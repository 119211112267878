// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sg d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sh d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sj d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sk d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sl d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sm d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sn d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_sp y_sp";
export var heroExceptionNormal = "s_sq y_sq";
export var heroExceptionLarge = "s_sr y_sr";
export var Title1Small = "s_ss y_ss y_sY y_sZ";
export var Title1Normal = "s_st y_st y_sY y_s0";
export var Title1Large = "s_sv y_sv y_sY y_s1";
export var BodySmall = "s_sw y_sw y_sY y_tg";
export var BodyNormal = "s_sx y_sx y_sY y_th";
export var BodyLarge = "s_sy y_sy y_sY y_tj";