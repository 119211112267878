// extracted by mini-css-extract-plugin
export var carouselContainer = "C_vn d_w d_H d_bf d_Z";
export var carouselContainerCards = "C_vp C_vn d_w d_H d_bf d_Z";
export var carouselContainerSides = "C_vq d_w d_H d_Z";
export var prevCarouselItem = "C_vr d_w d_H d_0 d_k";
export var prevCarouselItemL = "C_vs C_vr d_w d_H d_0 d_k";
export var moveInFromLeft = "C_vt";
export var prevCarouselItemR = "C_vv C_vr d_w d_H d_0 d_k";
export var moveInFromRight = "C_vw";
export var selectedCarouselItem = "C_vx d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "C_vy C_vx d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "C_vz C_vx d_w d_H d_Z d_bf";
export var nextCarouselItem = "C_vB d_w d_H d_0 d_k";
export var nextCarouselItemL = "C_vC C_vB d_w d_H d_0 d_k";
export var nextCarouselItemR = "C_vD C_vB d_w d_H d_0 d_k";
export var arrowContainer = "C_vF d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "C_vG C_vF d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "C_vH C_vG C_vF d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "C_vJ C_vF d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "C_vK";
export var nextArrowContainerHidden = "C_vL C_vJ C_vF d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "C_kG d_0";
export var prevArrow = "C_vM C_kG d_0";
export var nextArrow = "C_vN C_kG d_0";
export var carouselIndicatorContainer = "C_vP d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "C_vQ d_w d_bz d_bF";
export var carouselText = "C_vR d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "C_vS C_vR d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "C_vT d_b7";
export var carouselIndicator = "C_vV C_vT d_b7";
export var carouselIndicatorSelected = "C_vW C_vT d_b7";
export var arrowsContainerTopRight = "C_vX d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "C_vY d_0 d_bl d_bC";
export var arrowsContainerSides = "C_vZ d_0 d_bl d_bC";
export var smallArrowsBase = "C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "C_v1 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "C_v2 C_v1 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "C_v3 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "C_v4 C_v3 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "C_p9";
export var iconSmall = "C_v5";
export var multipleWrapper = "C_v6 d_bC d_bF d_bf";
export var multipleImage = "C_v7 d_bC";
export var sidesPrevContainer = "C_v8 C_v1 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "C_v9 C_v1 C_v0 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";